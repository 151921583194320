.keyboard-key {
  font-size: 20px;
  font-family: monospace;
  padding: 0px;
  margin: 0px;
}

.inner-letter-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}


.letter-wrapper1 {
  background-color: rgb(4, 2, 17);
  display:inline-block;
  padding-left: 3.75%;
  padding-right: 3.75%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 3px;
  height: 40px;
}
.letter-wrapper2 {
  background-color: rgb(4, 2, 17);
  display:inline-block;
  padding-left: 4.17%;
  padding-right: 4.17%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 3px;
  height: 40px;
}
.letter-wrapper3 {
  background-color: rgb(4, 2, 17);
  display:inline-block;
  padding-left: 4.69%;
  padding-right: 4.69%;
  padding-top: 7px;
  padding-bottom: 7px;
  border-radius: 3px;
  height: 40px;
}

.letter-wrapper1:active {
  background-color: rgb(118, 117, 131);
}
.letter-wrapper2:active {
  background-color: rgb(118, 117, 131);
}
.letter-wrapper3:active {
  background-color: rgb(118, 117, 131);
}

.keyboard-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px;
}

.inner-wrapper {
  width: 85%;
}

.key-row {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
