.callback-button {
  background-color: green;
  padding: 11px;
  margin: 1px;
  border-radius: 10px;
  width: 142px;
  border-style: none;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
}

.callback-button-disabled {
  background-color: grey;
  padding: 11px;
  margin: 1px;
  border-radius: 10px;
  width: 142px;
}

.callback-button-disabled:hover {
  cursor: not-allowed;
}

.callback-button:hover {
  background-color: darkgreen;
  padding: 12px;
  margin: 0px;
  cursor: pointer;
}

.callback-button:active {
  background-color: darkseagreen;
  padding: 12px;
  margin: 0px;
  cursor: pointer;
}

.outer-callback-button {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.callback-button-text {
  margin: 0px;
}