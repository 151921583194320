.boxes-container {
  display: flex;
  flex-direction: column;
}

.row-container {
  display: flex;
  justify-content: center;
}

.wordle-letter {
  margin: 0px;
  padding: 0px;
  font-size: 35px;
  color:#ffffff;
  font-family: 'Clear Sans', 'Helvetica Neue', Arial, sans-serif;
  font-weight: bold;
}

.box {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-inactive {
  border: 3px solid #202124;
  background-color: grey;
}

.box-active {
  border: 3px solid #202124;
  background-color: rgb(255, 255, 255);
}

.box-filled {
  border: 3px solid #202124;
  background-color: #557e70;
}

.box-filled-green {
  border: 3px solid #202124;
  background-color: #538d4e;
}

.box-filled-yellow {
  border: 3px solid #202124;
  background-color: #b59f3b;
}

.box-filled-black {
  border: 3px solid #202124;
  background-color: rgb(80, 80, 80);;
}