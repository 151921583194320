.outer-analysis-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.analyze-guesses-wrapper {
    display: flex;
    padding-top: 20px;
    width: 50%;
    height: 75vh;
    flex-direction: column;
}

.nav-away-hint {
    cursor: pointer;
}

.analysis-header-one {
    margin: 4px;
    text-align: center;
}

@media (max-width: 800px) {
    .analyze-guesses-wrapper {
        display: flex;
        padding-top: 20px;
        width: 100%;
        height: 75vh;
        flex-direction: column;
    }
}


.analysis-header-two {
    text-align: left;
    margin: 4px;
}

ul {
    margin: 4px;
    padding: 0px;
}

li {
    list-style-type: none;
    color: greenyellow;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 5px;
    font-family: monospace, Clear Sans,Helvetica Neue,Arial,sans-serif;
}

.guess-list-header {
    list-style-type: none;
    color: greenyellow;
    font-size: 25px;
    padding-top: 20px;
    padding-bottom: 5px;
    font-family: monospace, Clear Sans,Helvetica Neue,Arial,sans-serif;
}

.go-home-button {
    display: flex;
    justify-content: center;
}

/*
WORDS REMAINING BOX
*/
.words-rem-text {
    margin: 0px;
    margin-bottom: 4px;
}

.words-rem-box {
    display: inline-block;
    padding-right: 10px;
    padding-left: 10px;
}

.words-rem-box:hover {
    cursor: pointer;
}

.words-rem-box-wrapper {
    display: flex;
    width: calc(100%-20px);
    flex-wrap: wrap;
    justify-content: center;
    margin: 10px;
}

.outer-words-wrapper {
    border-width: 2px;
    border-style: solid;
    border-color: white;
}

.exp-words-link {
    margin: 0px;
    color: #72b4f8;
    text-decoration: underline; 
    font-family: monospace, Clear Sans,Helvetica Neue,Arial,sans-serif;
}

.exp-words-link:hover {
    cursor: pointer;
}

/*
POSSIBLE GUESS MODAL
*/
.guess-info-modal {
    display: flex;
    position: fixed;
    z-index: 1;
    padding-top: 25px;
    padding-left: 8px;
    width: 100%;
    height: 100%;
}

.inner-guess-modal {
    width: min(100%, 400px);
    height: 185px;
    border-width: 2.5px;
    border-color: white;
    border-style: solid;
    background-color: #37383b;
    padding: 15px;
}

@media(max-width: 1300px) {
    .guess-info-modal {
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        z-index: 1;
        left: 0px;
        top: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
    }

    .inner-guess-modal {
        width: 50%;
        height: 194px;
        border-width: 2.5px;
        border-color: white;
        border-style: solid;
        background-color: #37383b;
        padding: 15px;
    }
}

@media(max-width: 800px) {
    .guess-info-modal {
        display: flex;
        align-items: center;
        position: fixed;
        z-index: 1;
        left: 0px;
        top: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
    }

    .inner-guess-modal {
        width: 100%;
        height: 194px;
        border-width: 2.5px;
        border-color: white;
        border-style: solid;
        background-color: #37383b;
        padding: 15px;
    }
}

@media(max-width: 386px) {
    .inner-guess-modal {
        height: 225px;
    }
}

@media(max-width: 333px) {
    .inner-guess-modal {
        height: 250px;
    }
}

@media(max-width: 285px) {
    .inner-guess-modal {
        height: 275px;
    }
}

.guess-modal-text {
    margin: 0px;
    padding: 10px;
}

.guess-modal-header {
    margin: 0px;
    font-size: 40px;
}

.guess-modal-text-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;

}

.modal-click-intercepter {
    position: fixed;
    z-index: 1;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
}