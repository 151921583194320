.paywall-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.inner-paywall-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 60%;
}

@media(max-width: 700px) {
    .inner-paywall-wrapper {
        width: 100%;
    }
}

.benefit-item {
    color: lightgreen;
    padding: 0px;
    font-size: 20px;
}

.list-header {
    margin: 0px;
    margin-top: 5px;
}

.benefit-item-desc {
    margin-top: 0px;
}

.inner-paywall-page-wrapper {
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media (max-width: 800px) {
    .inner-paywall-page-wrapper {
        max-width: 100%;
    }
}

.paywall-cta-wrapper {
    display: flex;
}

.clickable {
    cursor: pointer;
}