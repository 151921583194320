.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
}

.inner-input-wrapper {
    display: flex;
    flex-direction: column;
    margin: 7px;
}

.signup-label {
    font-size: 20px;
}

.signup-input-box {
    width: 250px;
    border: 2.5px black solid;
    border-radius: 8px;
    background-color: whitesmoke;
    height: 25px;
    font-size: 20px;
}

.signup-page-title {
    font-size: 35px;
}

.signup-input-box:focus {
    background-color: lightgreen;
}

.hover-link {
    cursor: pointer;
}

.email-box {
    width: 250px !important;
    font-size: 15px;
}

.password-boxes {
    display: flex;
}

.small-input-box {
    width: 115px;
}

.submit-error-text {
    margin: 0px;
    color: rgb(255, 138, 138);
}

.submit-status-text {
    margin: 0px;
    margin-bottom: 5px;
    color: rgb(255, 255, 255);
}

.input-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}