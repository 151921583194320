.guess-input-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.yellow-wordle-link {
  color: yellow;
}

.submit-button {
  background-color: green;
  padding: 11px;
  margin: 1px;
  border-radius: 10px;
  width: 120px;
}

.submit-button-disabled {
  background-color: grey;
  padding: 11px;
  margin: 1px;
  border-radius: 10px;
  width: 120px;
}

.submit-button-disabled:hover {
  cursor: not-allowed;
}

.submit-button:hover {
  background-color: darkgreen;
  padding: 12px;
  margin: 0px;
  cursor: pointer;
}

.submit-button:active {
  background-color: darkseagreen;
  padding: 12px;
  margin: 0px;
  cursor: pointer;
}

.error-container-red {
  text-align: center;
  color: rgb(255, 138, 138);
}

.error-container-white {
  text-align: center;
  color: rgb(255, 255, 255);
}

.error-container-green {
  text-align: center;
  color: rgb(55, 214, 116);
}

.submit-text {
  margin: 0px;
}

.outer-submit-button {
  display: flex;
  justify-content: center;
  align-content: center;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
}

.submit-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
}