.guess-input-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.submit-button {
  background-color: green;
  padding: 11px;
  margin: 1px;
  border-radius: 10px;
}

.submit-button:hover {
  background-color: darkgreen;
  padding: 12px;
  margin: 0px;
  cursor: pointer;
}

.submit-button:active {
  background-color: darkseagreen;
  padding: 12px;
  margin: 0px;
  cursor: pointer;
}

.error-container-red {
  text-align: center;
  color: rgb(255, 138, 138);
}

.error-container-white {
  text-align: center;
  color: rgb(255, 255, 255);
}

.error-container-green {
  text-align: center;
  color: rgb(55, 214, 116);
}

.submit-text {
  margin: 0px;
}

.outer-submit-button {
  display: flex;
  justify-content: center;
  align-content: center;
}

.terminal-title {
  display: inline-block;
  border: 2px solid green;
  border-left-width: 0px;
  border-top-width: 0px;
}

.terminal-title-text {
  display: inline-block;
  font-family: Inconsolata, monospace;
  padding: 0px;
  padding-left: 2px;
  padding-bottom: 1px;
  padding-right: 1px;
  margin: 0px;
  color: green;
}

.terminal-body-text {
  font-family: Inconsolata, monospace;
  margin: 0px;
  margin-top: 5px;
  margin-left: 3px;
  width: 100%;
  color: lightgreen;
}

.bot-terminal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid green;
  background-color: black;
  height: 105px;
}