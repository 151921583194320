.bar {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    height: 25px;
    background-color: white;
    border-radius: 2px;
}

.inner-bar-val {
    color: black;
    padding-right: 9px;
    padding-bottom: 3px;
    margin: 0px;
}

.outer-bar-val {
    color: whitesmoke;
    padding-left: 9px;
    padding-bottom: 3px;
    margin: 0px;
}

.bar-label {
    margin: 0px;
    padding-right: 8px;
    font-size: 20px;
}

.bar-wrapper {
    display: flex;
    min-height: 29px;
    max-height: 29px;
    align-items: center;
}

.bar-chart-wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
}