.main-guess-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.enter-guesses {
  width: min(100%, 600px)
}

.guesses-header {
  margin-top: 4px;
  margin-bottom: 8px;
}