body {
  background-color: #202124;
  color: whitesmoke;
  height: 100vh;
}

#root {
  height: 100vh;
}

.home-div {
  display: flex;
  text-align: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  height: 75vh;
}

.login-text {
  color: white;
  text-decoration: none;
  padding: 0px;
  margin: 10px;
}

.login-text:hover {
  color: lightgrey;
}

a {
  text-decoration: none;
  display: inline-block;
}

h1.page-title {
  font-size: 50px;
  margin: 5px;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
    Introduced in IE 10.
    See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  */
  -ms-user-select: none;
  user-select: none;
}

.sign-out-text:hover {
  cursor: pointer;
}