.wordle-bot-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: min(600px, 100%);
}

.outer-bot-wrapper {
    display: flex;
    flex-direction: center;
    justify-content: center;
    align-items: center;
}

.wordle-number-title {
    margin: 10px;
}

.botshowcase-home-btn {
    margin: 13px;
}