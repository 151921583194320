span {
  display: inline-block;
}
.btn-text {
  color: black;
  margin: 0px;
  padding: 0px;
}

.inner-button {
  display: inline-block;
  background-color: lightblue;
  border-radius: 10px;
  padding: 11px;
  margin: 1px;
}

.inner-button:hover {
  cursor: pointer;
  background-color: #83BAC9;
  padding: 12px;
  margin: 0px;
}
