/* PROFILE STATS */
.profile-stats-text {
    margin: 3px;
    font-size: 17px;
}
.profile-stats-wrapper {
    padding: 0px;
    padding-top: 15px;
    width: 100%;
}
