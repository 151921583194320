/* MAIN PAGE */
.profile-page-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

/* FOLLOW LIST */
li.username-list {
    padding: 0px;
}
.user-follow-list {
    margin-left: 20px;
    margin-right: 20px;
    width: min(225px, 100%);
}
.section-divider {
    width: 3px;
    height: 150px;
    background-color: white;
    margin-left: 20px;
    margin-right: 20px;
}
.following-lists-wrapper {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 20px;
}
@media (max-width: 600px) {
    .section-divider {
        display: none;
    }
    .following-lists-wrapper {
        flex-direction: column;
        align-items: center;
    }
    .user-follow-list {
        margin-bottom: 20px;
        width: min(225px, 100%);
    }
}
h2 {
    margin: 0px;
}
.username-link {
    color: whitesmoke;
}
.follow-lists-div-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

/* FOLLOW INPUT BOX */
li.username-result {
    padding-top: 5px;
    color: rgb(77, 165, 247);
    font-size: 22px;
}
.horizontal-divider {
    background-color: white;
    height: 3px;
    width: 300px;
}
.follow-input-box {
    width: 220px;
    border: 2.5px black solid;
    border-radius: 8px;
    background-color: whitesmoke;
    height: 25px;
    font-size: 20px;
}
.copied-to-clipboard-visible {
    position: fixed;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -40%);
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color:green;
    transition: transform 0.3s;
}
.copied-to-clipboard-hidden {
    position: fixed;
    left: 50%;
    top: 0%;
    transform: translate(-50%, -105%);
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color:green;
    transition: transform 0.3s;
}

/* PROFILE TOGGLE */
.username-box-enabled {
    color: rgb(87, 212, 221);
    margin: 0px;
}
.username-box-disabled {
    color: rgb(199, 199, 199);
    margin: 0px;
}
.toggle-checkbox-enabled {
    width: 20px;
    height: 20px;
    background-color: lightgreen;
    border: 2px white solid;
    margin-right: 5px;
}
.toggle-checkbox-disabled {
    width: 20px;
    height: 20px;
    background-color: grey;
    border: 2px white solid;
    margin-right: 5px;
}
.profile-toggle-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
}