.paid-title-text {
    font-size: 40px;
    margin: 0px;
}

.current-user-text {
    margin: 0px;
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;
}

.sign-out-button {
    display: inline-block;
}

.sign-out-text:hover {
    cursor: pointer;
}

.copied-to-clipboard-visible {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -45px);
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color:green;
    transition: transform 0.3s;
}

.copied-to-clipboard-hidden {
    position: fixed;
    left: 50%;
    transform: translate(-50%, -105px);
    background-color: white;
    border-radius: 10px;
    border-style: solid;
    border-width: 2px;
    border-color:green;
    transition: transform 0.3s;
}

.notification-text {
    color: black;
    font-size: 25px;
    margin: 8px;
    margin-top: 30px;
}

.paid-nav-buttons {
    display: flex;
    flex-direction: row;
}

.paid-home-spacer {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 2px;
    margin-bottom: 2px;
}

.paid-home-wrapper {
    padding-top: 20px;
}

.inner-home-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 100%;
}

@media (max-width: 620px) {
    .paid-nav-buttons {
        display: flex;
        flex-direction: column;
    }
    .paid-home-wrapper {
        height: 95vh;
    }
    .inner-home-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        flex-direction: column;
        height: 100%;
        padding-top: 15px;
    }
}


/* HOME PAGE FEED */
.outer-feed-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.upload-time-color {
    color: rgb(196, 239, 253);
    font-size: 31.5px;
}
.guess-num-color {
    font-size: 37.5px !important;
}
.mobile-time-display {
    color: rgb(196, 239, 253);
    font-size: 20px;
    display: none;
}
.post-metadata-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 80%;
}
.user-details-wrapper {
    display: flex;
    min-width: 250px;
}

@media (max-width: 820px) {
    .upload-time-color {
        color: rgb(196, 239, 253);
        display: none;
    }
    .guess-num-color {
        font-size: 30px !important;
        display: none;
    }
    .post-metadata-wrapper {
        display: none;
    }
    .user-details-wrapper {
        min-width: 100%;
    }
    .mobile-time-display {
        display: inline-block;
    }
}

.post-user-details {
    display: flex;
    align-items: center;
    padding: 5px;
}

.post-upload-time {
    margin: 0px;
    font-size: 37.5px;
    margin-right: 15px;
}

.post-user-avatar {
    width: 60px;
    height: 60px;
    border-radius: 13px;
}

.post-user-name {
    font-size: 25px;
    padding-left: 10px;
    color: rgb(255, 255, 161);
    margin: 0px;
}

.post-header-bar {
    width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    text-align: center;
}

.wordle-box-wrapper {
    display: inline-block;
}

/* DESKTOP FORMATTING */
.feed-post-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 55%;

    border: 2px solid black;
    border-radius: 8px;
    border-color: whitesmoke;
    padding: 7px;
    margin-top: 7px;
    margin-bottom: 7px;

    background-color: rgb(70, 79, 88);
}

/* MOBILE FORMATTING */
@media (max-width: 620px) {
    .feed-post-wrapper {
        width: 93%;
    }
}

.trial-days-rem-text {
    margin: 0px;
    margin-bottom: 5px;
}

.busy:hover {
    cursor: wait;
}

.clickable:hover {
    cursor: pointer;
}

.signup-page-link {
    text-decoration: none;
    color: white;
}